"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _orderDetails = _interopRequireDefault(require("@/views/order/orderDetails.vue"));
var _user = require("@/api/user");
var _utils = require("@/utils");
var _bus = _interopRequireDefault(require("@/utils/bus.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    orderDetails: _orderDetails.default
  },
  props: {
    uid: {
      type: [Number, String]
    }
  },
  data: function data() {
    return {
      pagination: {
        limit: 10,
        page: 1,
        total: 0
      },
      list: [],
      loading: false,
      checkedList: [],
      fullLoading: false,
      orderId: null
    };
  },
  computed: {
    disabled: function disabled() {
      return this.loading || this.noMore;
    },
    noMore: function noMore() {
      return this.list.length >= this.pagination.total;
    }
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    handleRelaod: function handleRelaod() {
      this.pagination.page = 1;
      this.pagination.total = 0;
      this.checkedList = [];
      this.list = [];
      this.getList();
    },
    handleRead: function handleRead() {
      var _this = this;
      var checkedList = this.checkedList;
      var params = {
        ids: checkedList.join(",")
      };
      (0, _user.clearNotifications)(params).then(function (res) {
        _bus.default.$emit("update-message");
        _this.handleRelaod();
      });
    },
    getList: function getList() {
      var _this2 = this;
      this.fullLoading = true;
      var params = {
        limit: this.pagination.limit,
        page: this.pagination.page
      };
      if (this.uid) {
        params.uid = this.uid;
      }
      var list = this.list;
      (0, _user.getNotifications)(params).then(function (res) {
        _this2.list = res.list;
        _this2.pagination.total = res.total;
        _this2.$emit("updateTotal", res.total);
      }).finally(function () {
        _this2.fullLoading = false;
      });
    },
    onDetails: function onDetails(id) {
      this.orderId = id;
      this.$refs.orderDetail.show(id);
    },
    onUserDetails: function onUserDetails(uid) {
      this.$router.push({
        path: "/user/archives",
        query: {
          uid: uid
        }
      });
    },
    loadMore: function loadMore() {
      if (this.disabled) return;
      this.pagination.page = this.pagination.page + 1;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.pagination.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.pagination.limit = val;
      this.getList();
    },
    handleClick: function handleClick(id, idx, isRead) {
      if (isRead) return;
      var list = (0, _utils.deepClone)(this.list);
      var checkedList = (0, _utils.deepClone)(this.checkedList);
      list[idx]["checked"] = !list[idx]["checked"];
      if (checkedList.indexOf(id) > -1) {
        checkedList.splice(checkedList.indexOf(id), 1);
      } else {
        checkedList.push(id);
      }
      this.checkedList = checkedList;
      this.list = list;
    }
  }
};