var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "order-page",
      attrs: {
        title: _vm.title,
        visible: _vm.orderVisible,
        width: "1010px",
        "before-close": _vm.Close,
      },
      on: {
        "update:visible": function ($event) {
          _vm.orderVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "el-form",
            {
              ref: "pram",
              attrs: { model: _vm.pram, rules: _vm.rules },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              !_vm.isGoodsEdit
                ? [
                    !_vm.tmpId
                      ? _c(
                          "div",
                          [
                            _c("div", { staticClass: "shop-title" }, [
                              _vm._v("Order type"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "" } },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    attrs: { size: "large" },
                                    model: {
                                      value: _vm.isWater,
                                      callback: function ($$v) {
                                        _vm.isWater = $$v
                                      },
                                      expression: "isWater",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio",
                                      { attrs: { label: true, border: "" } },
                                      [_vm._v("Drinking water")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio",
                                      { attrs: { label: false, border: "" } },
                                      [_vm._v("Water dispenser")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isWater
                      ? [
                          _c("div", { staticClass: "shop-title" }, [
                            _vm._v("Subscription cycle"),
                          ]),
                          _vm._v(" "),
                          _c("el-form-item", { attrs: { label: "" } }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-input",
                                  {
                                    staticStyle: { width: "205px" },
                                    attrs: { type: "number", min: 1 },
                                    on: { blur: _vm.checkPeriod },
                                    model: {
                                      value: _vm.period,
                                      callback: function ($$v) {
                                        _vm.period = $$v
                                      },
                                      expression: "period",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "line-height": "36px" },
                                        attrs: { slot: "suffix" },
                                        slot: "suffix",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.periodTypeText) +
                                            "/items\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticStyle: { color: "#000" } }, [
                                  _vm._v(
                                    "\n                You can only enter multiples of " +
                                      _vm._s(_vm.shopDetail.period) +
                                      "\n              "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isWater
                      ? _c(
                          "div",
                          [
                            _c("div", { staticClass: "shop-title" }, [
                              _vm._v("Order date"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "" } },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "205px" },
                                  attrs: {
                                    type: "date",
                                    placeholder: "Please choose",
                                    "value-format": "yyyy-MM-dd hh:mm:ss",
                                    format: "yyyy-MM-dd",
                                    "picker-options": _vm.pickerOptions,
                                  },
                                  model: {
                                    value: _vm.provisionalOrderDate,
                                    callback: function ($$v) {
                                      _vm.provisionalOrderDate = $$v
                                    },
                                    expression: "provisionalOrderDate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "shop-title" }, [
                _vm._v("Current subscription"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "primary" }, [
                _c(
                  "div",
                  { staticClass: "shop-box", staticStyle: { padding: "0" } },
                  _vm._l(_vm.selectedProductObj.list, function (item, index) {
                    return _c("div", { key: index, staticClass: "shop-item" }, [
                      _c("div", { staticClass: "left" }, [
                        _c("img", {
                          staticClass: "image",
                          attrs: { src: item.image, alt: "" },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "column",
                              "justify-content": "space-between",
                            },
                          },
                          [
                            _c("div", [
                              _vm._v(
                                _vm._s(item.productName) + _vm._s(item.unitName)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticStyle: { display: "flex" } }, [
                              _c("p", [_vm._v("$")]),
                              _vm._v(" "),
                              _c("p", { staticClass: "price" }, [
                                _vm._v(_vm._s(item.price)),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "right" }, [
                        _c("i", {
                          staticClass: "el-icon-remove",
                          staticStyle: { color: "#0072E2" },
                          on: {
                            click: function ($event) {
                              return _vm.update(item.productId)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "line-height": "24px",
                              "min-width": "20px",
                              "text-align": "center",
                              "font-size": "18px",
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(item.cartNum) +
                                "\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "el-icon-circle-plus",
                          staticStyle: { color: "#0072E2" },
                          on: {
                            click: function ($event) {
                              return _vm.update(item.productId, "add")
                            },
                          },
                        }),
                      ]),
                    ])
                  }),
                  0
                ),
                _vm._v(" "),
                _c("div", { staticClass: "total" }, [
                  _c("p", { staticClass: "cash-pledge" }, [
                    _vm._v("\n            Total:"),
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v("$" + _vm._s(_vm.total)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "cash-pledge" }, [
                    _vm._v("\n            Goods total:"),
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v("$" + _vm._s(_vm.selectedProductObj.total)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "cash-pledge" }, [
                    _vm._v("\n            Bucket deposit:"),
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v("$" + _vm._s(_vm.depositAmt)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.shopDetail.deliveryPrice
                    ? _c("p", { staticClass: "cash-pledge" }, [
                        _vm._v("\n            freight: "),
                        _c("a", { attrs: { href: "#" } }, [
                          _vm._v("$" + _vm._s(_vm.shopDetail.deliveryPrice)),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              !_vm.isGoodsEdit
                ? _c(
                    "div",
                    [
                      _c(
                        "el-tabs",
                        {
                          on: {
                            "tab-click": function ($event) {
                              _vm.period = null
                            },
                          },
                          model: {
                            value: _vm.selectStore,
                            callback: function ($$v) {
                              _vm.selectStore = $$v
                            },
                            expression: "selectStore",
                          },
                        },
                        _vm._l(_vm.storeList, function (item, index) {
                          return _c("el-tab-pane", {
                            key: index,
                            attrs: { label: item.name, name: String(index) },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.shopDetail.areaId
                ? _c("div", [
                    _c("div", { staticClass: "shop-title" }, [
                      _vm._v("Our products"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "shop-box" },
                      _vm._l(_vm.shopDetail.waterList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "shop-item",
                            style: !item.number
                              ? "background: no-repeat;border: 1px solid #ddd;"
                              : "",
                          },
                          [
                            _c("div", { staticClass: "left" }, [
                              _c("img", {
                                staticClass: "image",
                                attrs: { src: item.image, alt: "" },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column",
                                    "justify-content": "space-between",
                                  },
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(item.title) + _vm._s(item.unitName)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c("p", [_vm._v("$")]),
                                      _vm._v(" "),
                                      _c("p", { staticClass: "price" }, [
                                        _vm._v(_vm._s(item.price)),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "right" }, [
                              _c("i", {
                                staticClass: "el-icon-remove",
                                style: !item.number
                                  ? "color:#c0c4cc"
                                  : "color:#0072E2",
                                on: {
                                  click: function ($event) {
                                    return _vm.update(item.id)
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "line-height": "24px",
                                    "min-width": "20px",
                                    "text-align": "center",
                                    "font-size": "18px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.number) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "el-icon-circle-plus",
                                staticStyle: { color: "#0072E2" },
                                on: {
                                  click: function ($event) {
                                    return _vm.update(item.id, "add")
                                  },
                                },
                              }),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                : _c(
                    "div",
                    {
                      staticStyle: {
                        padding: "30px",
                        "font-weight": "600",
                        "font-size": "20px",
                        "text-align": "center",
                        color: "#f14545",
                      },
                    },
                    [_vm._v("\n        Out of delivery range\n      ")]
                  ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          !_vm.tmpId
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.confirmLoading,
                      expression: "confirmLoading",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handlerSubmit("pram")
                    },
                  },
                },
                [_vm._v("Add new subscription")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.tmpId
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.confirmLoading,
                      expression: "confirmLoading",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.handleEdit },
                },
                [_vm._v("Confirm the changes")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.Close } }, [_vm._v("Cancel")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }