var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "title",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex", staticStyle: { "align-items": "center" } },
            [
              _vm.checkedList.length > 0
                ? _c(
                    "div",
                    { staticClass: "read", staticStyle: { color: "#1890FF" } },
                    [
                      _c("img", {
                        attrs: { src: require("@/assets/imgs/clear.svg") },
                      }),
                      _vm._v(" "),
                      _c("span", { on: { click: _vm.handleRead } }, [
                        _vm._v("Mark as read"),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-refresh-right",
                  },
                  on: { click: _vm.handleRelaod },
                },
                [_vm._v("Refresh")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.fullLoading,
              expression: "fullLoading",
            },
          ],
        },
        [
          _vm.pagination.total > 0
            ? _c("div", [
                _c(
                  "ul",
                  {
                    staticClass: "-list",
                    staticStyle: { overflow: "auto", padding: "0" },
                  },
                  _vm._l(_vm.list, function (itm, itmIndex) {
                    return _c(
                      "li",
                      {
                        key: itmIndex,
                        staticClass: "infinite-list-item sys_list_item",
                        class: itm.checked ? "active" : "",
                        on: {
                          click: function ($event) {
                            return _vm.handleClick(itm.id, itmIndex, itm.isRead)
                          },
                        },
                      },
                      [
                        !itm.isRead
                          ? _c("div", { staticClass: "unRead" }, [
                              _vm._v("New"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex" }, [
                          itm.orderNo
                            ? _c("img", {
                                attrs: {
                                  src: require("@/assets/imgs/car.svg"),
                                },
                              })
                            : _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "50px",
                                    height: "50px",
                                    background: "rgba(45, 205, 195, 1)",
                                    "border-radius": "50%",
                                    display: "flex",
                                    "align-items": "center",
                                    "justify-content": "center",
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/imgs/notice.svg"),
                                    },
                                  }),
                                ]
                              ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "12px" } },
                            [
                              _c("div", { staticClass: "time" }, [
                                _vm._v(_vm._s(itm.createTime)),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "text" }, [
                                _vm._v(_vm._s(itm.notificationInfo)),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        itm.orderNo
                          ? _c(
                              "el-button",
                              {
                                staticClass: "primary-border",
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.onDetails(itm.orderNo)
                                  },
                                },
                              },
                              [_vm._v("Order details")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        itm.skipType === 3
                          ? _c(
                              "el-button",
                              {
                                staticClass: "primary-border",
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.onUserDetails(itm.uid)
                                  },
                                },
                              },
                              [_vm._v("User details")]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.loading
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          color: "#1890ff",
                          "text-align": "center",
                          "line-height": "40px",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-loading",
                          staticStyle: {
                            color: "#1890ff",
                            "font-size": "20px",
                          },
                        }),
                        _vm._v("loading...\n      "),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.pagination.total > _vm.pagination.limit
            ? _c(
                "div",
                { staticClass: "block" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-size": _vm.pagination.limit,
                      "current-page": _vm.pagination.page,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.pagination.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.pageChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.pagination.total == 0
            ? _c(
                "el-card",
                {
                  staticStyle: {
                    "font-weight": "600",
                    "font-size": "20px",
                    "text-align": "center",
                  },
                },
                [_vm._v("\n      No notification yet\n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("order-details", {
        ref: "orderDetail",
        attrs: { orderId: _vm.orderId },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex" }, [
      _c("div", { staticClass: "line" }),
      _vm._v(" "),
      _c("div", { staticClass: "title_text" }, [_vm._v("Message record")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }