"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.archivesMixins = void 0;
var userApi = _interopRequireWildcard(require("@/api/user.js"));
var subscribeApi = _interopRequireWildcard(require("@/api/subscribe.js"));
var _order = require("@/api/order");
var _user2 = require("@/api/user");
var _validate = require("@/utils/validate");
var _utils = require("@/utils");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var archivesMixins = exports.archivesMixins = {
  data: function data() {
    return _defineProperty(_defineProperty(_defineProperty(_defineProperty({
      activeName: "second",
      payBillType: 0,
      messageTotal: 0,
      orderId: 0,
      uid: "",
      remark: "",
      periodType: "day",
      //day-日，week-周 month:月
      userInfo: {},
      orginUserInfo: {},
      addressList: [],
      sliderImage: [],
      sysList: [],
      tmpId: "",
      tmpInfo: {},
      loading: false,
      subscribeDetail: {},
      subscribeData: {
        data: [{
          id: 1
        }],
        total: 0,
        loading: false
      },
      invoiceData: {
        data: [],
        total: 0,
        loading: false
      },
      invoiceDetail: {},
      multipleSelection: [],
      multipleOrderSelection: [],
      //订单多选
      queryParams: {},
      total: 0,
      orderData: {
        data: [],
        total: 0,
        loading: false
      },
      pagination: {
        limit: 10,
        page: 1
      },
      invoiceQueryParams: {},
      sliderUserImage: [],
      timeVal: "",
      addressData: [],
      addressId: "",
      address: {},
      DisableSelection: false
    }, "loading", false), "checkRemarkList", []), "isChange", false), "text", "");
  },
  computed: {
    //废弃
    // isChange() {
    //   const subscribeDetail = this.subscribeDetail;
    //   const { originPeriod, period, subType, originSubType } = subscribeDetail;
    //   return originPeriod != period || subType != originSubType;
    // },
    noMore: function noMore() {
      return this.sysList.length >= 20;
    },
    disabled: function disabled() {
      return this.loading || this.noMore;
    },
    inputMin: function inputMin() {
      var periodType = this.periodType;
      var min = 1;
      if (periodType === "day") {
        min = 7;
      } else {
        min = 1;
      }
      return min;
    },
    periodTypeText: function periodTypeText() {
      var periodType = this.subscribeDetail.subType;
      var text = "Week";
      if (periodType === "day") {
        text = "Day";
      } else {
        text = "Week";
      }
      return text;
    },
    periodList: function periodList() {
      var arr = [];
      if (this.subscribeDetail && this.subscribeDetail.areaPeriod) {
        for (var i = 0; i < 6; i++) {
          var obj = {
            name: "每" + (this.subscribeDetail.areaPeriod + i > 1 ? this.subscribeDetail.areaPeriod * (i + 1) : "") + "周",
            value: this.subscribeDetail.areaPeriod * (i + 1),
            desc: "每" + (this.subscribeDetail.areaPeriod + i > 1 ? this.subscribeDetail.areaPeriod * (i + 1) : "") + "周1次"
          };
          arr.push(obj);
        }
      } else {
        arr = [];
      }
      return arr;
    }
  },
  mounted: function mounted() {
    if (this.$route.query.uid) {
      this.uid = this.$route.query.uid;
      this.getUserDetail();
      this.getUserAddress();
    }
  },
  methods: {
    checkPeriod: function checkPeriod() {
      var _this$subscribeDetail = this.subscribeDetail,
        period = _this$subscribeDetail.period,
        originPeriod = _this$subscribeDetail.originPeriod,
        areaPeriod = _this$subscribeDetail.areaPeriod;
      console.log(originPeriod);
      areaPeriod = areaPeriod ? areaPeriod : 2;
      if (period % areaPeriod !== 0) {
        this.subscribeDetail = Object.assign({}, this.subscribeDetail, {
          period: originPeriod
        });
        this.isChange = false;
      } else {
        if (period == originPeriod) {
          this.isChange = false;
        } else {
          this.isChange = true;
        }
      }
      return period % areaPeriod == 0;
    },
    dayTypeChange: function dayTypeChange(value) {
      console.log(value);
      var period = this.subscribeDetail.period;
      if (value == "day") {
        this.subscribeDetail.period = period * 7;
      } else {
        this.subscribeDetail.period = parseInt(period / 7);
      }
    },
    getLabel: function getLabel(groupName, realName, phone, address) {
      return groupName + "," + realName + "," + phone + "," + address;
    },
    getImageSrc: function getImageSrc(avatar) {
      var subStr = "http";
      if (avatar && avatar.startsWith(subStr)) {
        return avatar;
      } else {
        return "https://img.h2owaterco.com/" + avatar;
      }
    },
    cellClass: function cellClass(row) {
      if (this.DisableSelection) {
        if (row.columnIndex === 0) {
          return "DisableSelection";
        }
      }
    },
    selectable: function selectable(row, index) {
      if (row.status == 1) {
        return false;
      } else {
        return true;
      }
    },
    handleAddressEditOk: function handleAddressEditOk() {
      this.getUserAddress();
    },
    handleClick: function handleClick(tab, event) {
      this.activeName = tab.name;
      this.pagination.page = 1;
      if (tab.name === "second") {
        this.getUserSubList();
      }
      if (tab.name === "third") {
        this.getUserInvoice();
      }
      if (tab.name === "fourth") {
        this.getUserOrderList();
      }
    },
    handleTabClick: function handleTabClick() {
      this.activeName = "third";
      this.pagination.page = 1;
      this.getUserInvoice();
    },
    //新增订阅-入参-地址id-模板详情
    handleAddSubscribe: function handleAddSubscribe() {
      var params = {
        address: this.address,
        uid: this.uid
      };
      this.$refs.subscribeEdit.add(params);
    },
    handleEditGoods: function handleEditGoods(info) {
      var params = {
        address: this.address,
        tmpId: info.tmpId,
        tmpInfo: info,
        uid: this.uid
      };
      this.$refs.subscribeEdit.goodsEdit(params);
    },
    //修改订阅-入参-地址id-模板详情
    handleEditSubscribe: function handleEditSubscribe() {
      var tmpProductList = this.subscribeDetail.tmpProductList;
      var params = {
        address: this.address,
        tmpId: tmpProductList[0].tmpId,
        uid: this.uid
      };
      this.$refs.subscribeEdit.edit(params);
    },
    //删除订阅-入参-地址id-模板详情
    cancelSubscribe: function cancelSubscribe() {
      var _this2 = this;
      var params = {
        tmpId: this.tmpId
      };
      this.$confirm("Are you sure you want to cancel your subscription?", "Tips", {
        confirmButtonText: "confirm",
        cancelButtonText: "cancel",
        type: "warning"
      }).then(function () {
        subscribeApi.deleteTmp(params).then(function (res) {
          _this2.$message.success("Update successfully");
          _this2.getUserSubList();
        });
      });
    },
    handleChangeAddress: function handleChangeAddress(addressId) {
      var addressList = this.addressList;
      this.address = addressList.find(function (item) {
        return item.id == addressId;
      });
      if (this.activeName == "second") {
        this.getUserSubList();
      } else {
        this.getUserOrderList();
      }
    },
    handleOpenAddress: function handleOpenAddress() {
      this.$refs.addressList.show(this.uid);
    },
    handleOpenEmail: function handleOpenEmail() {
      this.$refs.emailManage.show(this.userInfo);
    },
    handleCellChange: function handleCellChange(record, dataIndex, value, address) {
      var _this3 = this;
      if (!dataIndex) {
        this.$message.warning("请设置需要更新的列");
        return;
      }
      console.log(record[dataIndex]);
      if (record[dataIndex] == value) return;
      this.userInfo = Object.assign({}, this.userInfo, _defineProperty(_defineProperty({}, dataIndex, value), "isPromoter", false));
      var params = _objectSpread({}, this.userInfo);
      if (address) {
        params.addressId = address.id;
      }
      userApi.userUpdateApi({
        id: this.userInfo.uid
      }, params).then(function (res) {
        if (dataIndex == "mark" || dataIndex == "img") {
          _this3.userInfo = Object.assign({}, _this3.userInfo, {
            isSystem: true
          });
        }
        _this3.$message.success("Update successful");
        _this3.orginUserInfo = (0, _utils.deepClone)(_this3.userInfo);
      });
    },
    handleChangeSubDetail: function handleChangeSubDetail(event, dataIndex) {
      var _this4 = this;
      var subscribeDetail = (0, _utils.deepClone)(this.subscribeDetail);
      if (!subscribeDetail.period || subscribeDetail.period == 0) {
        this.subscribeDetail = Object.assign({}, subscribeDetail, {
          period: subscribeDetail.originPeriod
        });
        return;
      }
      if (subscribeDetail.period == subscribeDetail.originPeriod && subscribeDetail.subType == subscribeDetail.originSubType) {
        this.$message.success("Update success");
        return;
      }
      var orderInfoList = subscribeDetail.tmpProductList.map(function (item) {
        return {
          cartNum: item.payNum,
          image: item.image,
          price: item.price,
          productId: item.productId,
          productName: item.productName,
          unitName: item.unitName
        };
      });
      var params = {
        orderInfoList: orderInfoList,
        nextOrderDate: subscribeDetail.nextOrderDate,
        period: subscribeDetail.period,
        tmpId: subscribeDetail.tmpId,
        subType: subscribeDetail.subType
      };
      subscribeApi.tmpUpdate(params).then(function (res) {
        _this4.$message.success("Update successfully");
        _this4.getUserSubList();
      });
    },
    handleRouter: function handleRouter() {
      // this.$router.push({
      //   path: "/user/historyInvoice",
      //   query: {
      //     uid: this.uid
      //   }
      // });
      this.$refs.historyInvoice.show();
    },
    onDetails: function onDetails(id) {
      this.orderId = id;
      this.$refs.orderDetail.show(id);
    },
    tableRowClassName: function tableRowClassName(_ref2) {
      var row = _ref2.row,
        rowIndex = _ref2.rowIndex;
      if (row.status == 9) {
        return "error-row";
      } else {
        if (rowIndex % 2 == 0) {
          return "";
        } else {
          return "hilight-row";
        }
      }
    },
    handleDetail: function handleDetail(row) {
      if (row.status == 0) {
        this.$refs.invoiceModal.getDetail(row);
        this.$refs.invoiceModal.orderVisible = true;
      } else {
        if (row.chequeId) {
          this.$refs.checkDetail.show({
            id: row.chequeId
          });
        }
      }
    },
    clearSelection: function clearSelection() {
      this.$refs.multipleTable.clearSelection();
    },
    clearOrderSelection: function clearOrderSelection() {
      this.$refs.multipleOrderTable.clearSelection();
    },
    handleOrderEdit: function handleOrderEdit() {
      this.$refs.orderEdit.open(this.subscribeDetail);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.pagination.limit = val;
      this.getUserOrderList();
    },
    handlePageChange: function handlePageChange(page) {
      this.pagination.page = page;
      this.getUserOrderList();
    },
    //
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      var totalPrice = val.length && val.reduce(function (total, record) {
        return total + (parseFloat(record.unpaid) || 0);
      }, 0);
      this.total = totalPrice;
    },
    handleOrderSelectionChange: function handleOrderSelectionChange(val) {
      this.multipleOrderSelection = val;
    },
    handleDateEdit: function handleDateEdit(row) {
      this.$refs.dateEdit.open(row);
    },
    getUserAddress: function getUserAddress() {
      var _this5 = this;
      userApi.addressList({
        uid: this.uid,
        page: 1,
        limit: 50
      }).then(function (res) {
        _this5.addressList = res.list || [];
        if (res.list.length) {
          var address = res.list.find(function (item) {
            return item.isDefault;
          });
          _this5.$set(_this5, "address", address);
          _this5.addressId = address.id;
          _this5.orderAddressId = address.id;
          // if (this.activeName == "second") {
          _this5.getUserSubList();
          // }
        }
      });
    },
    getUserSubList: function getUserSubList() {
      var that = this;
      that.subscribeData.loading = true;
      (0, _user2.userSubDetail)({
        uid: that.uid,
        addressId: that.addressId
      }).then(function (res) {
        var waterDispenserList = res.waterDispenserList || [];
        var waterDispenserTotal = 0;
        waterDispenserList.map(function (item) {
          waterDispenserTotal += item.price * item.payNum;
        });
        console.log("waterDispenserTotal", waterDispenserTotal);
        that.subscribeDetail = Object.assign({}, res, {
          originPeriod: res.period,
          waterDispenserTotal: waterDispenserTotal.toFixed(2),
          originSubType: res.subType
        });
        that.tmpId = res.tmpId;
        var obj = {};
        that.subscribeData.data = res.tmpList ? res.tmpList.map(function (item) {
          if (!item.isNew) {
            if (obj["isFirst"] == undefined) {
              obj["isFirst"] = item.id;
            }
          }
          if (obj["isFirst"] == item.id) {
            that.subscribeDetail.nextOrderDate = item.nextOrderDate;
          }
          return _objectSpread(_objectSpread({}, item), {}, {
            isFirst: obj["isFirst"] == item.id ? true : false,
            productInfo: res.productInfo ? typeof res.productInfo === "string" ? JSON.parse(res.productInfo) : res.productInfo : res.tmpProductList
          });
        }) : [];
      }).finally(function () {
        that.subscribeData.loading = false;
      });
    },
    loadMore: function loadMore() {
      var _this6 = this;
      this.loading = true;
      setTimeout(function () {
        _this6.sysList.push({
          createTime: "2024-05-06 11:29:00",
          text: "无不良拖欠记录，信用等级优良"
        });
      }, 2000);
    },
    getUserInvoice: function getUserInvoice() {
      var that = this;
      that.invoiceData.loading = true;
      (0, _user2.userBillList)(_objectSpread(_objectSpread({
        uid: that.uid
      }, this.invoiceQueryParams), this.pagination)).then(function (res) {
        var disableLength = 0;
        that.invoiceData.data = res.list;
        res.list.map(function (item) {
          disableLength += item.status;
        });
        that.invoiceData.total = res.total;
        that.DisableSelection = disableLength ? true : false;
      }).finally(function () {
        that.invoiceData.loading = false;
      });
    },
    getUserDetail: function getUserDetail() {
      var _this7 = this;
      userApi.userInfoApi({
        id: this.uid
      }).then(function (res) {
        _this7.userInfo = (0, _utils.deepClone)(res);
        _this7.orginUserInfo = (0, _utils.deepClone)(res);
        _this7.sliderUserImage = res.img ? res.img.split(",") : [];
        _this7.sliderImage = res.systemRemarkImg ? res.systemRemarkImg.split(",") : [];
      });
    },
    modalPicTap: function modalPicTap(tit, image) {
      var _this = this;
      var attr = [];
      this.$modalUpload(function (img) {
        if (tit === "1") {
          _this.form[image] = img[0].sattDir;
          _this.form = Object.assign({}, _this.form, {
            img: img[0].sattDir
          });
          _this.$set(_this.form, "img", img[0].sattDir);
        }
        if (tit === "2") {
          if (image == "sliderImage") {
            img.map(function (item) {
              attr.push(item.attachment_src);
              _this.sliderImage.push(item.sattDir);
            });
            var imageStr = _this.sliderImage.join(",");
            _this.handleCellChange(_this.userInfo, "systemRemarkImg", imageStr, _this.address);
          }
          if (image == "sliderUserImage") {
            img.map(function (item) {
              attr.push(item.attachment_src);
              _this.sliderUserImage.push(item.sattDir);
            });
            var _imageStr = _this.sliderUserImage.join(",");
            _this.handleCellChange(_this.userInfo, "img", _imageStr, _this.address);
          }
        }
      }, tit, "store");
    },
    handleDeleteImg: function handleDeleteImg(index) {
      this.sliderImage.splice(index, 1);
      var systemRemarkImg = this.sliderImage.join(",");
      this.handleCellChange(this.userInfo, "systemRemarkImg", systemRemarkImg, this.address);
    },
    handleDeleteUserImg: function handleDeleteUserImg(index) {
      this.sliderUserImage.splice(index, 1);
      var imgStr = this.sliderUserImage.join(",");
      this.handleCellChange(this.userInfo, "img", imgStr, this.address);
    },
    handleSearch: function handleSearch() {
      this.pagination.page = 1;
      this.getUserOrderList();
    },
    handleDateChange: function handleDateChange(val) {
      this.timeVal = val;
      this.queryParams.dateLimit = val ? val + "," + val : "";
      this.handleSearch();
    },
    getUserOrderList: function getUserOrderList() {
      var _this8 = this;
      var params = _objectSpread(_objectSpread(_objectSpread({}, this.pagination), this.queryParams), {}, {
        uid: this.uid,
        addressId: this.addressId,
        type: 0
      });
      this.orderData.loading = true;
      (0, _order.orderListApi)(params).then(function (res) {
        _this8.orderData.data = res.list;
        _this8.orderData.total = res.total;
      }).finally(function () {
        _this8.orderData.loading = false;
      });
    },
    handleCancel: function handleCancel() {
      var _this9 = this;
      this.$confirm("Are you sure you want to cancel your debit order?", "Tips", {
        confirmButtonText: "confirm",
        cancelButtonText: "cancel",
        type: "warning"
      }).then(function () {
        var waterDispenserList = _this9.subscribeDetail.waterDispenserList;
        var ids = waterDispenserList.map(function (item) {
          return item.id;
        });
        var params = {
          ids: ids.join(",")
        };
        (0, _order.waterOrderCancel)(params).then(function (res) {
          _this9.$message.success("Operation successfully");
          _this9.getUserSubList();
        });
      });
    },
    getTempDetail: function getTempDetail() {
      var _this10 = this;
      this.loading = true;
      subscribeApi.tmpDetail(this.tmpId).then(function (data) {
        _this10.tmpInfo = data;
      }).finally(function () {
        _this10.loading = false;
      });
    },
    handlePay: function handlePay(row) {
      var ids = [row.invoiceNo];
      var params = {
        ids: ids,
        totalPrice: row.totalPrice
      };
      this.$refs.payDialog.open(params);
    },
    handleBatchPay: function handleBatchPay() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning("Select at least one items");
        return;
      }
      var ids = this.multipleSelection.map(function (item) {
        return item.invoiceNo;
      });
      var params = {
        ids: ids,
        totalPrice: this.total
      };
      this.$refs.payDialog.open(params);
    },
    // 邮件发送
    handleBatchSend: function handleBatchSend() {
      var _this11 = this;
      if (this.multipleSelection.length == 0) {
        this.$message.warning("Select at least one items");
        return;
      }
      var ids = this.multipleSelection.map(function (item) {
        return item.id;
      });
      userApi.sendEmail({
        billId: ids.join(",")
      }).then(function (res) {
        _this11.$message.success("Mail sent successfully");
        setTimeout(function () {
          _this11.clearSelection();
        }, 500);
      });
    },
    //支票支付刷新用户信息+用户发票列表
    invoicePayOk: function invoicePayOk() {
      this.getUserDetail();
      this.getUserInvoice();
    },
    handleDelete: function handleDelete(tmpId, idx) {
      var that = this;
      this.$confirm("Are you sure you want to delete the selected subscription order?", "Tips", {
        confirmButtonText: "delete",
        cancelButtonText: "cancel",
        type: "warning"
      }).then(function () {
        subscribeApi.deleteChildTmp({
          id: tmpId
        }).then(function () {
          that.$message.success("Delete successfully");
          that.getUserSubList();
        });
      });
    },
    handleBatchDelete: function handleBatchDelete() {
      var tmpIds = this.multipleOrderSelection.map(function (item) {
        return item.id;
      });
      var that = this;
      this.$confirm("Are you sure you want to delete the selected subscription order?", "Tips", {
        confirmButtonText: "delete",
        cancelButtonText: "cancel",
        type: "warning"
      }).then(function () {
        subscribeApi.deleteChildTmp({
          id: tmpIds.join(",")
        }).then(function () {
          that.$message.success("Delete successfully");
          that.getUserSubList();
        });
      });
    },
    //用户备注发送
    handleSend: function handleSend() {
      var _this12 = this;
      if (this.text == "") return;
      var params = {
        uid: this.uid,
        content: this.text
      };
      userApi.saveRemark(params).then(function (res) {
        _this12.$message.success("Sent successfully");
        _this12.text = "";
        _this12.getUserDetail();
      });
    },
    handleClearRemark: function handleClearRemark() {
      this.checkRemarkList = [];
    },
    // 用户备注删除
    handleDeleteRemark: function handleDeleteRemark() {
      var _this13 = this;
      var checkRemarkList = this.checkRemarkList;
      userApi.deleteRemark({
        id: checkRemarkList.join(",")
      }).then(function (res) {
        _this13.handleClearRemark();
        _this13.$message.success("Delete successfully");
        _this13.getUserDetail();
      });
    },
    // 用户余额操作记录
    handleOpenBanlance: function handleOpenBanlance() {
      this.$refs.balanceRecord.show();
    }
  }
};