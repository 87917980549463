var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "order-page",
      attrs: {
        visible: _vm.dialogVisible,
        title: _vm.title,
        "destroy-on-close": "",
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "end",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "submission",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit("formValidate")
                        },
                      },
                    },
                    [_vm._v("submit")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v("cancel"),
                  ]),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.fullscreenLoading,
              expression: "fullscreenLoading",
            },
          ],
          ref: "formValidate",
          staticClass: "formValidate",
          attrs: {
            rules: _vm.ruleValidate,
            model: _vm.formValidate,
            "label-position": "top",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "product name", prop: "storeName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "249",
                          placeholder: "Please enter",
                          disabled: _vm.isDisabled,
                        },
                        model: {
                          value: _vm.formValidate.storeName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "storeName", $$v)
                          },
                          expression: "formValidate.storeName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Product type", prop: "productType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          on: { change: _vm.handleTypeChange },
                          model: {
                            value: _vm.formValidate.productType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "productType", $$v)
                            },
                            expression: "formValidate.productType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "Water dispenser", value: 1 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "Drinking water", value: 0 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _vm.formValidate.productType == 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "debit date", prop: "debitDate" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "date",
                              "value-format": "yyyy-MM-dd hh:mm:ss",
                              format: "yyyy-MM-dd",
                              placeholder: "Please select",
                            },
                            model: {
                              value: _vm.formValidate.debitDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "debitDate", $$v)
                              },
                              expression: "formValidate.debitDate",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "price：", prop: "price" } },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          min: 0,
                          placeholder: "price",
                          disabled: _vm.isDisabled,
                        },
                        model: {
                          value: _vm.formValidate.price,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "price", $$v)
                          },
                          expression: "formValidate.price",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "unit", prop: "unitName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: "Please enter",
                          disabled: _vm.isDisabled,
                        },
                        model: {
                          value: _vm.formValidate.unitName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "unitName", $$v)
                          },
                          expression: "formValidate.unitName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "weight(L)：" } },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          min: 0,
                          placeholder: "weight(L)",
                          disabled: _vm.isDisabled,
                        },
                        model: {
                          value: _vm.formValidate.weight,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "weight", $$v)
                          },
                          expression: "formValidate.weight",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "detail：", prop: "storeInfo" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "250",
                          rows: 3,
                          placeholder: "detail",
                          disabled: _vm.isDisabled,
                        },
                        model: {
                          value: _vm.formValidate.storeInfo,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "storeInfo", $$v)
                          },
                          expression: "formValidate.storeInfo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "product photo", prop: "image" } },
                    [
                      _c("div", { staticClass: "remark" }, [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { disabled: _vm.isDisabled },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("1", "image")
                              },
                            },
                          },
                          [
                            _vm.formValidate.image
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: { src: _vm.formValidate.image },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }