function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { productLstApi, storeAddproduct, productDeleteApi, productBatchDelete } from "@/api/store";
import productEdit from "./productEdit.vue";
export default {
  name: "shopList",
  components: {
    productEdit: productEdit
  },
  props: {
    storeId: {
      type: [Number, String]
    }
  },
  data: function data() {
    return {
      props: {
        children: "child",
        label: "name",
        value: "id",
        emitPath: false
      },
      deliveryVisible: false,
      headeNum: [],
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      form: {
        productId: "",
        number: ""
      },
      loading: false,
      tableFrom: {
        page: 1,
        limit: 10,
        cateId: "",
        keywords: ""
        // type: '1'
      },
      productId: "",
      categoryList: [],
      merCateList: [],
      shopList: [],
      objectUrl: process.env.VUE_APP_BASE_API,
      dialogVisible: false,
      multipleSelection: []
    };
  },
  mounted: function mounted() {},
  methods: {
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      this.tableFrom.storeId = this.storeId;
      productLstApi(this.tableFrom).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.$emit("change", res.total);
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        _this.$message.error(res.message);
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleBatchDelete: function handleBatchDelete() {
      var ids = this.multipleOrderSelection.map(function (item) {
        return item.id;
      });
      var that = this;
      this.$confirm("Are you sure you want to delete the selected products?", "Tips", {
        confirmButtonText: "delete",
        cancelButtonText: "cancel",
        type: "warning"
      }).then(function () {
        productBatchDelete({
          ids: ids.join(",")
        }).then(function (res) {
          that.$message.success("Delete successfully");
          that.getList();
        });
      });
    },
    handleAdd: function handleAdd() {
      this.$refs.modalForm.add();
    },
    handleEdit: function handleEdit(record) {
      this.$refs.modalForm.edit(_objectSpread(_objectSpread({}, record), {}, {
        productType: record.type
      }));
    },
    handleDelete: function handleDelete(id, type) {
      var that = this;
      this.$confirm("Are you sure you want to delete the product?", "Tips", {
        confirmButtonText: "delete",
        cancelButtonText: "cancel",
        type: "warning"
      }).then(function () {
        productDeleteApi(id, "delete").then(function () {
          that.$message.success("Delete successfully");
          that.getList();
        });
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this2 = this;
      storeAddproduct({
        productId: row.productId,
        storeId: this.storeId,
        type: row.isShow ? 0 : 1
      }).then(function () {
        _this2.getList();
      }).catch(function () {
        row.isShow = !row.isShow;
      });
    }
  }
};