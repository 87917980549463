//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    type: {
      type: String,
      default: "order"
    },
    height: {
      type: String,
      default: "calc(100vh - 400px)"
    }
  },
  methods: {
    getOrderStatus: function getOrderStatus(t) {
      var obj = {
        0: "pending",
        1: "pending",
        2: "on route",
        3: "complete",
        4: "complete",
        8: "canceled",
        9: "exception"
      };
      return obj[t];
    },
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      if (row.status == 9) {
        return "error-row";
      }
    },
    // 订单详情
    onDetails: function onDetails(id) {
      this.$emit("onDetails", id);
    },
    // 订单详情
    onUser: function onUser(uid) {
      this.$emit("onUser", uid);
    }
  }
};