var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "order-page",
      attrs: {
        visible: _vm.visible,
        title: "Allocate Vehicles",
        "before-close": _vm.handleClose,
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "order_box" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _vm.showDriver
                        ? [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "driverId",
                                  label: "Please select the assigned driver",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder:
                                        "Please select the assigned driver",
                                      filterable: "",
                                    },
                                    on: { change: _vm.handleChangeDriver },
                                    model: {
                                      value: _vm.form.driverId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "driverId", $$v)
                                      },
                                      expression: "form.driverId",
                                    },
                                  },
                                  _vm._l(_vm.driverList, function (item) {
                                    return _c("el-option", {
                                      key: item.uid,
                                      attrs: {
                                        label: item.account,
                                        value: item.uid,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.form.driverId
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "carId",
                                      required: "",
                                      label: "Please select the assigned car",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          placeholder:
                                            "Please select the assigned car",
                                          filterable: "",
                                          clearable: false,
                                          disabled: _vm.carSelectDisabled,
                                        },
                                        model: {
                                          value: _vm.form.carId,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "carId", $$v)
                                          },
                                          expression: "form.carId",
                                        },
                                      },
                                      _vm._l(_vm.carList, function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            value: item.id,
                                            label:
                                              "License plate:" +
                                              item.carNum +
                                              "  Car model" +
                                              (item.carType == 1
                                                ? "Truck"
                                                : "Mini Van"),
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        : _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "carId",
                                required: "",
                                label: "Please select the assigned car",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder:
                                      "Please select the assigned car",
                                    filterable: "",
                                    clearable: false,
                                  },
                                  model: {
                                    value: _vm.form.carId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "carId", $$v)
                                    },
                                    expression: "form.carId",
                                  },
                                },
                                _vm._l(_vm.carList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      value: item.id,
                                      label:
                                        "License plate:" +
                                        item.carNum +
                                        "  Car model" +
                                        (item.carType == 1
                                          ? "Truck"
                                          : "Mini Van"),
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-row-bet query_select_all" },
            [
              _c("div", [_vm._v("Please select an order for delivery")]),
              _vm._v(" "),
              _c(
                "el-checkbox",
                {
                  on: { change: _vm.handleCheckAll },
                  model: {
                    value: _vm.checkedAll,
                    callback: function ($$v) {
                      _vm.checkedAll = $$v
                    },
                    expression: "checkedAll",
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.checkedAll ? "cancel selection" : "select all")
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                display: "grid",
                "grid-template-columns": "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                "grid-gap": "5px",
              },
            },
            _vm._l(_vm.form.orderList, function (item, i) {
              return _c(
                "el-popover",
                {
                  key: i,
                  staticClass: "item",
                  attrs: {
                    width: "350",
                    "open-delay": 500,
                    trigger: "hover",
                    effect: "light",
                    placement: "top-start",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "column",
                      },
                    },
                    [
                      _c(
                        "span",
                        [
                          _c(
                            "a",
                            { staticClass: "label", attrs: { href: "#" } },
                            [_vm._v("ORDER ID:")]
                          ),
                          _c("el-link", { attrs: { type: "primary" } }, [
                            _vm._v(_vm._s(item.orderId)),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", [
                        _c(
                          "a",
                          { staticClass: "label", attrs: { href: "#" } },
                          [_vm._v("USER INFO:")]
                        ),
                        _vm._v(" "),
                        _c("a", { attrs: { href: "#" } }, [
                          _vm._v(_vm._s(item.userAddress)),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tag",
                    {
                      staticStyle: {
                        background: "#ebebeb",
                        "border-color": "#ebebeb",
                        color: "#000",
                        height: "35px",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "center",
                      },
                      style:
                        item.status == 3 || item.status == 4
                          ? "background: rgb(232, 244, 255);border-color: rgb(232, 244, 255);"
                          : "",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: {
                            disabled: item.status == 3 || item.status == 4,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleChange($event, i)
                            },
                          },
                          model: {
                            value: item.checked,
                            callback: function ($$v) {
                              _vm.$set(item, "checked", $$v)
                            },
                            expression: "item.checked",
                          },
                        },
                        [_vm._v("Order " + _vm._s(item.sort))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handlerSubmit("form")
                },
              },
            },
            [_vm._v("Confirm")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("changeLoadingVehicle", {
        ref: "loadCar",
        attrs: { deliveryDate: _vm.deliveryDate },
        on: { update: _vm.handleUpdate },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }