//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { menuListApi, menuInfo, menuUpdate, menuAdd, menuDelete } from '@/api/systemadmin';
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { Debounce } from '@/utils/validate';
export default {
  name: "Menu",
  components: {
    Treeselect: Treeselect
  },
  data: function data() {
    return {
      // 遮罩层
      listLoading: true,
      // 显示搜索条件
      showSearch: true,
      // 菜单表格树数据
      menuList: [],
      // 菜单树选项
      menuOptions: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 是否展开，默认全部折叠
      isExpandAll: false,
      // 重新渲染表格状态
      refreshTable: true,
      // 查询参数
      queryParams: {
        name: '',
        menuType: ''
      },
      // 表单参数
      form: {},
      //请求到的menu数据
      menuDataList: [],
      // 表单校验
      rules: {
        name: [{
          required: true,
          message: "菜单名称不能为空",
          trigger: "blur"
        }],
        sort: [{
          required: true,
          message: "菜单顺序不能为空",
          trigger: "blur"
        }]
      },
      statusOptions: [{
        value: 'M',
        label: '目录'
      }, {
        value: 'C',
        label: '菜单'
      }, {
        value: 'A',
        label: '按钮'
      }],
      showStatus: [{
        label: '显示',
        value: true
      }, {
        label: '隐藏',
        value: false
      }]
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    // 点击图标
    addIcon: function addIcon() {
      var _this = this;
      _this.$modalIcon(function (icon) {
        _this.form.icon = icon;
      });
    },
    /** 查询菜单列表 */getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      menuListApi(this.queryParams).then(function (res) {
        var obj = {},
          menuList = [];
        res.forEach(function (item) {
          obj = item;
          obj.parentId = item.pid;
          obj.children = [];
          menuList.push(obj);
        });
        _this2.menuDataList = menuList;
        _this2.menuList = _this2.handleTree(menuList, "menuId");
        _this2.listLoading = false;
      });
    },
    /** 转换菜单数据结构 */normalizer: function normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id ? node.id : 0,
        label: node.name ? node.name : '主目录',
        children: node.children
      };
    },
    /** 查询菜单下拉树结构 */getTreeselect: function getTreeselect() {
      this.menuOptions = [];
      var menu = {
        menuId: 0,
        menuName: '主类目',
        children: []
      };
      menu.children = this.handleTree(this.menuDataList, "menuId");
      this.menuOptions.push(menu);
    },
    // 取消按钮
    cancel: function cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset: function reset() {
      this.form = {
        menuId: '',
        parentId: 0,
        name: '',
        icon: '',
        menuType: "M",
        sort: 0,
        isShow: true,
        component: '',
        perms: ''
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.queryParams = {
        name: '',
        menuType: ''
      }, this.handleQuery();
    },
    /** 新增按钮操作 */handleAdd: function handleAdd(row) {
      this.reset();
      if (row != null && row.id) {
        this.form.pid = row.id;
      } else {
        this.form.pid = 0;
      }
      this.open = true;
      this.title = "add menu";
    },
    /** 展开/折叠操作 */toggleExpandAll: function toggleExpandAll() {
      var _this3 = this;
      this.refreshTable = false;
      this.isExpandAll = !this.isExpandAll;
      this.$nextTick(function () {
        _this3.refreshTable = true;
      });
    },
    /** 修改按钮操作 */handleUpdate: function handleUpdate(row) {
      var _this4 = this;
      var loading = this.$loading({
        lock: true,
        text: 'Loading'
      });
      this.reset();
      this.getTreeselect();
      menuInfo(row.id).then(function (response) {
        _this4.form = response;
        _this4.open = true;
        _this4.title = "modify menu";
        loading.close();
      });
    },
    /** 提交按钮 */
    submitForm: Debounce(function () {
      var _this5 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          if (_this5.form.id != undefined) {
            menuUpdate(_this5.form).then(function (response) {
              _this5.$modal.msgSuccess("successful");
              _this5.open = false;
              _this5.getList();
            });
          } else {
            menuAdd(_this5.form).then(function (response) {
              _this5.$modal.msgSuccess("successful");
              _this5.open = false;
              _this5.getList();
            });
          }
        }
      });
    }),
    /** 删除按钮操作 */handleDelete: function handleDelete(row) {
      var _this6 = this;
      this.$modal.confirm('Data prior to deletion"' + row.name + '"？').then(function () {
        return menuDelete(row.id);
      }).then(function () {
        _this6.getList();
        _this6.$modal.msgSuccess("successful");
      }).catch(function () {});
    }
  }
};