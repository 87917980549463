//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { deepClone } from "@/utils";
import * as userApi from "@/api/user.js";
import editAddress from "@/views/user/list/components/editAddress.vue";
export default {
  components: {
    editAddress: editAddress
  },
  data: function data() {
    return {
      visible: false,
      uid: 0,
      addressList: [],
      address: {},
      loading: false
    };
  },
  methods: {
    show: function show(uid) {
      this.uid = uid;
      this.visible = true;
      this.getUserAddress();
    },
    handleEditAddress: function handleEditAddress(row) {
      this.$refs.editAddress.edit(row);
    },
    handleAddAddress: function handleAddAddress() {
      this.$refs.editAddress.add();
    },
    handleClose: function handleClose() {
      this.visible = false;
    },
    getUserAddress: function getUserAddress() {
      var _this = this;
      this.loading = true;
      userApi.addressList({
        uid: this.uid,
        page: 1,
        limit: 50
      }).then(function (res) {
        var addressList = deepClone(res.list) || [];
        if (res.list.length) {
          _this.address = res.list.find(function (item) {
            return item.isDefault;
          });
          _this.addressList = addressList.filter(function (item) {
            return !item.isDefault;
          });
        }
      }).finally(function () {
        _this.loading = false;
      });
    },
    handleOk: function handleOk() {
      this.getUserAddress();
      this.$emit("ok");
    },
    setDefaultAddress: function setDefaultAddress(id) {
      var _this2 = this;
      this.loading = true;
      userApi.setDefaultAddress({
        id: id
      }).then(function (res) {
        _this2.handleOk();
      }).catch(function () {
        _this2.loading = false;
      });
    },
    handleDeleteAddress: function handleDeleteAddress(id) {
      var _this3 = this;
      this.$confirm("Delete this address?", "Tips", {
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(function () {
        _this3.loading = true;
        userApi.deleteAddress({
          id: id
        }).then(function (res) {
          _this3.getUserAddress();
        }).catch(function () {
          _this3.loading = false;
        });
      });
    }
  }
};