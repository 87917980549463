function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { addressList, listAndProduct, create, buyNow } from "@/api/user";
import { mapGetters } from "vuex";
import * as orderApi from "@/api/order.js";
import { userListApi } from "@/api/user";
import { Debounce } from "@/utils/validate";
import selectNew from "@/components/select/index";
import { number } from "echarts/lib/export";
import editAddress from "./editAddress.vue";
export default {
  components: {
    selectNew: selectNew,
    editAddress: editAddress
  },
  props: {},
  data: function data() {
    return {
      uid: null,
      subType: "week",
      optionList: [],
      orderVisible: false,
      dataSelected: null,
      orderType: undefined,
      provisionalOrderDate: undefined,
      pagination: {
        show: true,
        current: 1,
        size: 5,
        total: 0,
        layout: "prev, next"
      },
      period: null,
      loading: false,
      constants: this.$constants,
      pram: {
        storeId: null
      },
      isWater: true,
      rules: {
        amount: [{
          required: true,
          message: "refund amount",
          trigger: ["blur", "change"]
        }],
        refundReason: [{
          required: true,
          message: "refund notes",
          trigger: ["blur", "change"]
        }]
      },
      listData: [],
      payPrice: 999,
      addressData: [],
      addressId: null,
      address: {},
      storeList: [],
      shopList: [],
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      selectStore: "0"
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["storeId"])), {}, {
    periodTypeText: function periodTypeText() {
      var periodType = this.subType;
      var text = "Week";
      if (periodType === "day") {
        text = "Day";
      } else {
        text = "Week";
      }
      return text;
    },
    total: function total() {
      var _this = this;
      var num = 0;
      if (this.storeList.length <= 0) return num;
      this.shopDetail.waterList.forEach(function (e) {
        num += e.number * e.price + Number(_this.shopDetail.depositAmt) * e.number;
      });
      num = num ? num + Number(this.shopDetail.deliveryPrice) : num;
      return num.toFixed(2);
    },
    depositAmt: function depositAmt() {
      var _this2 = this;
      var num = 0;
      if (this.storeList.length <= 0) return num;
      this.shopDetail.waterList.forEach(function (e) {
        num += Number(_this2.shopDetail.depositAmt) * e.number;
      });
      return num.toFixed(2);
    },
    // 选中水厂
    shopDetail: function shopDetail() {
      if (this.storeList.length <= 0) return {};else {
        var isWater = this.isWater ? 0 : 1;
        var index = parseInt(this.selectStore);
        var storeList = this.storeList;
        var waterList = JSON.parse(JSON.stringify(storeList[index].waterList));
        var newWaterList = waterList.filter(function (item) {
          return item.type === isWater;
        });
        var shopDetail = Object.assign({}, storeList[index], {
          waterList: newWaterList
        });
        return shopDetail;
      }
    },
    emptyText: function emptyText() {
      var length = this.addressData.length;
      var shopDetail = this.shopDetail;
      var text = "";
      if (length) {
        if (shopDetail.period) {
          text = "No data";
        } else {
          text = "Beyond delivery range";
        }
      } else {
        text = "No data";
      }
      return text;
    },
    periodList: function periodList() {
      var arr = [];
      if (!this.shopDetail) {
        arr = [];
      } else {
        if (this.shopDetail && this.shopDetail.period) {
          for (var i = 0; i < 6; i++) {
            var obj = {
              name: this.shopDetail.period * (i + 1) + "week",
              value: this.shopDetail.period * (i + 1)
            };
            arr.push(obj);
          }
        } else {
          arr = [];
        }
      }
      return arr;
    }
  }),
  mounted: function mounted() {},
  methods: {
    checkPeriod: function checkPeriod() {
      var period = this.period;
      var areaPeriod = this.shopDetail.period;
      console.log(period, areaPeriod);
      if (period % areaPeriod !== 0) {
        this.period = "";
      }
      return period % areaPeriod == 0;
    },
    // 编辑/添加地址
    handleAddAddress: function handleAddAddress() {
      this.$refs.editAddress.add();
    },
    handleEditAddress: function handleEditAddress() {
      var address = this.address;
      this.$refs.editAddress.edit(address);
    },
    change: function change(e) {
      var _this3 = this;
      this.addressData.map(function (item) {
        if (e == item.id) {
          _this3.address = item;
        }
      });
      this.selectStore = "0";
      this.loading1 = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.loading = true;
      this.getListAndProduct();
    },
    // 修改购物车数量
    update: function update(i, type) {
      if (!this.shopDetail.waterList[i].number && !type) return;
      type ? this.shopDetail.waterList[i].number++ : this.shopDetail.waterList[i].number--;
      this.$forceUpdate();
    },
    handleUpdateAddress: function handleUpdateAddress() {
      this.getUserAddress(this.uid);
    },
    getUserAddress: function getUserAddress(uid) {
      var _this4 = this;
      this.orderType;
      this.loading = true;
      this.uid = uid;
      this.isWater = true;
      addressList({
        page: 1,
        limit: 9999,
        uid: uid
      }).then(function (res) {
        _this4.addressData = res.list || [];
        _this4.addressId = _this4.addressData[0].id;
        _this4.address = _this4.addressData[0];
        _this4.getListAndProduct();
      }).catch(function () {
        _this4.loading = false;
      });
    },
    //    获取水厂商品
    getListAndProduct: function getListAndProduct() {
      var _this5 = this;
      var pram = {
        latitude: this.address.latitude,
        longitude: this.address.longitude,
        uid: this.uid
      };
      listAndProduct(pram).then(function (res) {
        res.list.map(function (item) {
          item.scoreAvg = item.scoreAvg || 0;
          item.depositAmt = item.depositAmt || 0;
          item.waterList.map(function (e) {
            e.number = 0;
          });
        });
        // const storeList =res.list.filter((item)=>{
        //   return item.period
        // })
        _this5.storeList = res.list || [];
        _this5.pram.storeId = _this5.storeList[0].id;
        if (_this5.loading1) {
          _this5.loading1.close();
        }
      }).finally(function () {
        _this5.loading = false;
      });
    },
    add: function add() {
      this.pram.shopList.push({
        value: "",
        key: Date.now()
      });
    },
    Close: function Close() {
      this.selectStore = "0";
      this.orderVisible = false;
      this.storeList = [];
      this.address = {};
      this.addressId = null;
      this.period = null;
    },
    handlerSubmit: Debounce(function (form) {
      var _this6 = this;
      var orderDetails = [];
      var detail = this.shopDetail;
      detail.waterList.map(function (e) {
        if (e.number) {
          orderDetails.push({
            productId: e.id,
            productNum: e.number
          });
        }
      });
      if (orderDetails.length <= 0) {
        this.$message.error("Please select the ordered product");
        return;
      }
      // if (!this.period && this.isWater) {
      //   this.$message.error("Please select a subscription period");
      //   return;
      // }
      var none = [undefined, null, "", 0];
      if (none.includes(this.provisionalOrderDate)) {
        this.$message.error("Please select order date");
        return;
      }
      var params = {
        orderDetails: orderDetails,
        areaId: detail.areaId,
        preOrderType: "shoppingCart",
        uid: this.uid,
        addressId: this.address.id
      };
      var that = this;
      this.$confirm("Are you sure you want to place the order?", "prompt", {
        confirmButtonText: "confirm",
        cancelButtonText: "cancel",
        type: "warning"
      }).then(function () {
        that.loading1 = that.$loading({
          lock: true,
          text: "Placing an order",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        buyNow(params).then(function (res) {
          console.log(res);
          var preOrderNo = res.preOrderNo;
          var createParams = {
            addressId: that.address.id,
            preOrderNo: preOrderNo,
            payType: "app",
            shippingType: "1",
            payChannel: "app",
            useIntegral: true,
            isOneTime: false,
            couponId: 0,
            mark: "",
            uid: _this6.uid,
            period: null,
            subType: _this6.subType,
            isWater: _this6.isWater,
            provisionalOrderDate: _this6.provisionalOrderDate
          };
          create(createParams).then(function (res1) {
            that.$message.success("success");
            that.$emit("handlerSubmit");
            that.loading1.close();
            that.Close();
          }).catch(function () {
            that.loading1.close();
          });
        }).catch(function () {
          that.loading1.close();
        });
      });
    }),
    handlerSave: function handlerSave() {
      var _this7 = this;
      orderApi.orderRefundApi(this.pram).then(function (data) {
        _this7.$message.success("successful");
        _this7.$emit("handlerSubmit");
        _this7.loading = false;
      }).catch(function (e) {
        _this7.loading = false;
      });
    }
  }
};