var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-popover",
        {
          attrs: { placement: "bottom", width: "400" },
          on: { show: _vm.getMessageList },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c("span", { attrs: { slot: "reference" }, slot: "reference" }, [
            _c("div", { staticStyle: { position: "relative" } }, [
              _c("i", {
                staticClass: "el-icon-bell",
                staticStyle: { cursor: "pointer" },
              }),
              _vm._v(" "),
              _vm.unreadNum > 0
                ? _c("div", { staticClass: "badge" })
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
            },
            [
              _vm.total == 0
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "title_header" },
                      [
                        _c("div", [_vm._v("Notification")]),
                        _vm._v(" "),
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary", underline: false },
                            on: { click: _vm.handleMore },
                          },
                          [_vm._v("more>")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          height: "60px",
                          "line-height": "60px",
                          "text-align": "center",
                        },
                      },
                      [_vm._v("\n          No notification yet\n        ")]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.total > 0
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "title_header" },
                      [
                        _c("div", [_vm._v("Notification")]),
                        _vm._v(" "),
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary", underline: false },
                            on: { click: _vm.handleMore },
                          },
                          [_vm._v("more>")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "sys_list",
                        staticStyle: { height: "50vh", "overflow-y": "scroll" },
                      },
                      [
                        _c(
                          "ul",
                          _vm._l(
                            _vm.notificationList,
                            function (itm, itmIndex) {
                              return _c(
                                "li",
                                {
                                  key: itmIndex,
                                  staticClass:
                                    "infinite-list-item sys_list_item",
                                  class: itm.checked ? "active" : "",
                                },
                                [
                                  _c("div", { staticClass: "flex" }, [
                                    itm.orderNo
                                      ? _c("img", {
                                          attrs: {
                                            src: require("@/assets/imgs/car.svg"),
                                          },
                                        })
                                      : _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "50px",
                                              height: "50px",
                                              background:
                                                "rgba(45, 205, 195, 1)",
                                              "border-radius": "50%",
                                              display: "flex",
                                              "align-items": "center",
                                              "justify-content": "center",
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/imgs/notice.svg"),
                                              },
                                            }),
                                          ]
                                        ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "margin-left": "12px",
                                          flex: "1",
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "time" }, [
                                          _vm._v(_vm._s(itm.createTime)),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "text" }, [
                                          _vm._v(_vm._s(itm.notificationInfo)),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  itm.orderNo
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "primary-border",
                                          attrs: { size: "mini" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.onDetails(itm.orderNo)
                                            },
                                          },
                                        },
                                        [_vm._v("Order details")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  itm.skipType === 3
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "primary-border",
                                          attrs: { size: "mini" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.onUserDetails(itm.uid)
                                            },
                                          },
                                        },
                                        [_vm._v("User details")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("order-details", {
        ref: "orderDetail",
        attrs: { orderId: _vm.orderId },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }