var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "divBox relative",
      class: _vm.tabPosition,
      staticStyle: { "user-select": "none" },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "padding-bottom": "24px",
            display: "flex",
            "grid-gap": "10px",
            height: "64px",
            "align-items": "center",
            position: "sticky",
            top: "0",
            "background-color": "#f5f5f5",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.prevMethod },
            },
            [
              _vm.tabPosition === "dayGridDay"
                ? [_c("span", [_vm._v("last day")])]
                : _vm._e(),
              _vm._v(" "),
              _vm.tabPosition === "dayGridWeek"
                ? [_c("span", [_vm._v("last week")])]
                : _vm._e(),
              _vm._v(" "),
              _vm.tabPosition === "dayGridMonth"
                ? [_c("span", [_vm._v("last month")])]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c("el-date-picker", {
            attrs: {
              type: _vm.dateType,
              format: _vm.dateFormat,
              "value-format": "yyyy-MM-dd hh:mm:ss",
              placeholder: "Please choose",
            },
            on: { change: _vm.datePicker },
            model: {
              value: _vm.initialDate,
              callback: function ($$v) {
                _vm.initialDate = $$v
              },
              expression: "initialDate",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.nextMethod },
            },
            [
              _vm.tabPosition === "dayGridDay"
                ? [_c("span", [_vm._v("next day")])]
                : _vm._e(),
              _vm._v(" "),
              _vm.tabPosition === "dayGridWeek"
                ? [_c("span", [_vm._v("next week")])]
                : _vm._e(),
              _vm._v(" "),
              _vm.tabPosition === "dayGridMonth"
                ? [_c("span", [_vm._v("next month")])]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-radio-group",
            {
              staticClass: "dayGrid-group",
              on: { change: _vm.tabPositionChange },
              model: {
                value: _vm.tabPosition,
                callback: function ($$v) {
                  _vm.tabPosition = $$v
                },
                expression: "tabPosition",
              },
            },
            [
              _c(
                "el-radio-button",
                { staticClass: "dayGrid", attrs: { label: "dayGridDay" } },
                [_vm._v("Day")]
              ),
              _vm._v(" "),
              _c(
                "el-radio-button",
                { staticClass: "dayGrid", attrs: { label: "dayGridWeek" } },
                [_vm._v("Week")]
              ),
              _vm._v(" "),
              _c(
                "el-radio-button",
                { staticClass: "dayGrid", attrs: { label: "dayGridMonth" } },
                [_vm._v("Month")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tabPosition == "dayGridWeek",
                  expression: "tabPosition == 'dayGridWeek'",
                },
              ],
            },
            [
              _c("span", { staticClass: "labels" }, [
                _vm._v("Driver assigned"),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "labels" }, [
                _vm._v("Driver to be assigned"),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "labels" }, [_vm._v("Undelivered")]),
              _vm._v(" "),
              _c("span", { staticClass: "labels" }, [_vm._v("Change driver")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tabPosition == "dayGridDay",
                  expression: "tabPosition == 'dayGridDay'",
                },
              ],
            },
            [
              _vm.type == 1
                ? _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-s-cooperation" },
                      on: {
                        click: function ($event) {
                          _vm.type = 2
                        },
                      },
                    },
                    [_vm._v("List display")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-s-cooperation" },
                      on: {
                        click: function ($event) {
                          _vm.type = 1
                        },
                      },
                    },
                    [_vm._v("Big Picture Show")]
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tabPosition == "dayGridMonth",
              expression: "tabPosition == 'dayGridMonth'",
            },
          ],
          staticStyle: { "background-color": "#fff" },
        },
        [
          [
            _vm.show
              ? _c("FullCalendar", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "fullCalendar",
                  staticClass: "demo-app-calendar",
                  attrs: { options: _vm.calendarOptions },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "eventContent",
                        fn: function (arg) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  height: "100%",
                                  position: "relative",
                                  "z-index": "1",
                                  cursor: "pointer",
                                },
                                on: {
                                  tap: function ($event) {
                                    return _vm.goto(arg.event.extendedProps)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "event_start_str" }, [
                                  _c("div", [
                                    _vm._v(_vm._s(arg.event.startStr)),
                                  ]),
                                  _vm._v(" "),
                                  arg.event.extendedProps.all
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "order-item",
                                          on: {
                                            click: function ($event) {
                                              return _vm.eventClick(arg.event)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                All Order: " +
                                              _vm._s(
                                                arg.event.extendedProps.all
                                              ) +
                                              " items\n              "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  arg.event.extendedProps.preOrder
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "order-item",
                                          on: {
                                            click: function ($event) {
                                              return _vm.eventClick(arg.event)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                Pre-order: " +
                                              _vm._s(
                                                arg.event.extendedProps.preOrder
                                              ) +
                                              " items\n              "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2430813064
                  ),
                })
              : _vm._e(),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tabPosition == "dayGridDay",
              expression: "tabPosition == 'dayGridDay'",
            },
          ],
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.type == 2,
                      expression: "type == 2",
                    },
                  ],
                  staticClass: "swiper mainSwiper",
                  staticStyle: { height: "calc(100vh - 170px)" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "swiper-wrapper",
                      staticStyle: { height: "calc(100vh - 210px)" },
                    },
                    [
                      _vm._l(_vm.dayListData.car, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: "car" + index,
                            staticClass: "swiper-slide",
                            staticStyle: { "min-width": "780px" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "title" },
                              [
                                index == 0
                                  ? [
                                      _c("div", { staticClass: "line" }),
                                      _vm._v(
                                        "\n                Driver to be assigned\n              "
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "center_top",
                                staticStyle: {
                                  "grid-template-columns": "auto",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "grid",
                                      "grid-template-columns":
                                        "10% 20% 20% 17% 17% 20%",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "driver-img",
                                      attrs: {
                                        src: require("@/assets/imgs/car.svg"),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "text" }, [
                                      _c("span", { staticClass: "order" }, [
                                        _vm._v(_vm._s(item.allNum) + " items"),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v("Total number of orders"),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "text" }, [
                                      _c("span", { staticClass: "order" }, [
                                        _vm._v(_vm._s(item.allBuckets) + " "),
                                        item.restNum
                                          ? _c("span", [
                                              _vm._v(
                                                "(" + _vm._s(item.restNum) + ")"
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v("Total number of barrels"),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "text" }, [
                                      _c("span", { staticClass: "car" }, [
                                        _vm._v(
                                          _vm._s(
                                            item.carType == 1
                                              ? "Truck"
                                              : "Mini Van"
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Car model")]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "text" }, [
                                      _c("span", { staticClass: "car" }, [
                                        _vm._v(_vm._s(item.carNum || "--")),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("License plate")]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "text" }, [
                                      _c("span", { staticClass: "car" }, [
                                        _vm._v(
                                          _vm._s(item.driverTime + "h" || "--")
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Delivery time")]),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                      "justify-content": "space-between",
                                      "margin-top": "10px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text",
                                        staticStyle: {
                                          "align-items": "flex-start",
                                        },
                                      },
                                      [
                                        _c("span", { staticClass: "car" }, [
                                          _vm._v(_vm._s(item.driverName)),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "text-overflow" },
                                          [
                                            _vm._l(
                                              _vm.getList(item.productList),
                                              function (sku) {
                                                return _c(
                                                  "span",
                                                  {
                                                    key: sku.productId,
                                                    staticStyle: {
                                                      "margin-right": "20px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(sku.productName) +
                                                        "：\n                      "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "car",
                                                        staticStyle: {
                                                          "font-size": "12px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(sku.number) +
                                                            "G"
                                                        ),
                                                        sku.extraNum
                                                          ? _c("span", [
                                                              _vm._v(
                                                                " (" +
                                                                  _vm._s(
                                                                    sku.extraNum
                                                                  ) +
                                                                  ")"
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            _vm._v(" "),
                                            item.productList &&
                                            item.productList.length > 4
                                              ? _c(
                                                  "el-link",
                                                  {
                                                    attrs: {
                                                      type: "primary",
                                                      underline: false,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleLoadCarDetail(
                                                          item.carId
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("more>")]
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                      "justify-content": "flex-end",
                                      "margin-top": "12px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleLoadCar(
                                              item,
                                              item.allNum
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Loading vehicle")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                          plain: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeDate(item)
                                          },
                                        },
                                      },
                                      [_vm._v("Reschedule delivery")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("order-list", {
                              attrs: {
                                data: item.orderList,
                                height: "calc(100vh - 400px)",
                              },
                              on: { onDetails: _vm.onDetails },
                            }),
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.dayListData.driver, function (item, i) {
                        return _c(
                          "div",
                          {
                            key: "driver" + i,
                            staticClass: "swiper-slide",
                            staticStyle: { "min-width": "780px" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "title" },
                              [
                                i == 0
                                  ? [
                                      _c("div", { staticClass: "line" }),
                                      _vm._v(
                                        "\n                Order assigned\n                "
                                      ),
                                      _vm._m(0, true),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "center_top",
                                staticStyle: {
                                  "grid-template-columns": "auto",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "grid",
                                      "grid-template-columns":
                                        "8% 20% 10% 15% 15% 20% 15%",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "driver-img",
                                      attrs: {
                                        src: require("@/assets/imgs/driver.webp"),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "text" }, [
                                      _c("span", { staticClass: "car" }, [
                                        _vm._v(_vm._s(item.allNum) + " items"),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v("Total number of orders"),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "text" }, [
                                      _c("span", { staticClass: "car" }, [
                                        _vm._v(_vm._s(item.deliveredNum || 0)),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Order sent")]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "text" }, [
                                      _c("span", { staticClass: "order" }, [
                                        _vm._v(_vm._s(item.residueNum)),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Remaining orders")]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "text" }, [
                                      _c("span", { staticClass: "order" }, [
                                        _vm._v(_vm._s(item.takeNum)),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Remaining buckets")]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "text" }, [
                                      _c("span", { staticClass: "order" }, [
                                        _vm._v(_vm._s(item.orderResidueNum)),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v("Remaining order buckets"),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "text" }, [
                                      _c("span", { staticClass: "car" }, [
                                        _vm._v(
                                          _vm._s(item.driverTime + "h" || "-")
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Delivery time")]),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { "margin-top": "20px" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text",
                                        staticStyle: {
                                          "align-items": "flex-start",
                                        },
                                      },
                                      [
                                        _c("span", { staticClass: "car" }, [
                                          _vm._v(_vm._s(item.driverName)),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "flex-end",
                                          "justify-content": "space-between",
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "text" }, [
                                          _c("div", { staticClass: "flex" }, [
                                            _c(
                                              "span",
                                              { staticClass: "flex" },
                                              [
                                                _vm._v(
                                                  "Car model:\n                        "
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "car",
                                                    staticStyle: {
                                                      "font-size": "12px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          item.carType == 1
                                                            ? "Truck"
                                                            : "Mini Van"
                                                        ) +
                                                        "\n                        "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "flex",
                                                staticStyle: {
                                                  "margin-left": "20px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "Delivery order:\n                        "
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "car",
                                                    staticStyle: {
                                                      "font-size": "12px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(item.carNum) +
                                                        "\n                        "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "flex" },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  size: "mini",
                                                  icon: "el-icon-refresh-right",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.getDetail(item)
                                                  },
                                                },
                                              },
                                              [_vm._v("Refresh")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "is-ghost",
                                                attrs: {
                                                  type: "default",
                                                  size: "mini",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changeDelivery(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Change delivery")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "is-ghost",
                                                attrs: {
                                                  type: "default",
                                                  size: "mini",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.reorderingShow(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Reordering")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "is-ghost",
                                                attrs: {
                                                  type: "default",
                                                  size: "mini",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.goto(
                                                      "deliveryMap",
                                                      {
                                                        driverId: item.driverId,
                                                        date: _vm.initialDate,
                                                        carId: item.carId,
                                                      }
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Delivery map")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("order-list", {
                              attrs: {
                                data: item.orderList,
                                height: "calc(100vh - 400px)",
                              },
                              on: { onDetails: _vm.onDetails },
                            }),
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.dayListData.provisional, function (item, i) {
                        return _c(
                          "div",
                          {
                            key: "exception" + i,
                            staticClass: "swiper-slide",
                            staticStyle: { "min-width": "780px" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "title" },
                              [
                                i == 0
                                  ? [
                                      _c("div", { staticClass: "line" }),
                                      _vm._v(
                                        "\n                Temporary order\n              "
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "center_top",
                                staticStyle: {
                                  "grid-template-columns": "auto",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "grid",
                                      "grid-template-columns": "10% 20% 70%",
                                      "justify-content": "flex-end",
                                      "align-items": "flex-end",
                                    },
                                  },
                                  [
                                    _c("el-image", {
                                      staticClass: "driver-img",
                                    }),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "text" }, [
                                      _c("span", { staticClass: "car" }, [
                                        _vm._v(_vm._s(item.allNum) + " items"),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v("Total number of orders"),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex",
                                        staticStyle: {
                                          "justify-content": "flex-end",
                                          "align-items": "flex-end",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "is-ghost",
                                            attrs: {
                                              type: "default",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeCar(item)
                                              },
                                            },
                                          },
                                          [_vm._v("Allocate vehicles")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("order-list", {
                              attrs: {
                                data: item.orderList,
                                height: "calc(100vh - 334px)",
                              },
                              on: { onDetails: _vm.onDetails },
                            }),
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.dayListData.overTime, function (item, i) {
                        return _c(
                          "div",
                          {
                            key: "exception" + i,
                            staticClass: "swiper-slide",
                            staticStyle: { "min-width": "780px" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "title" },
                              [
                                i == 0
                                  ? [
                                      _c("div", { staticClass: "line" }),
                                      _vm._v(
                                        "\n                Exception order\n              "
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "center_top",
                                staticStyle: {
                                  "grid-template-columns": "auto",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "grid",
                                      "grid-template-columns": "10% 20% 70%",
                                      "justify-content": "flex-end",
                                      "align-items": "flex-end",
                                    },
                                  },
                                  [
                                    _c("el-image", {
                                      staticClass: "driver-img",
                                    }),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "text" }, [
                                      _c("span", { staticClass: "car" }, [
                                        _vm._v(_vm._s(item.allNum) + " items"),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v("Total number of orders"),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex",
                                        staticStyle: {
                                          "justify-content": "flex-end",
                                          "align-items": "flex-end",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "is-ghost",
                                            attrs: {
                                              type: "default",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeCar(item)
                                              },
                                            },
                                          },
                                          [_vm._v("Allocate vehicles")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("order-list", {
                              attrs: {
                                data: item.orderList,
                                height: "calc(100vh - 334px)",
                              },
                              on: { onDetails: _vm.onDetails },
                            }),
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _vm._l(
                        _vm.dayListPreData.list,
                        function (item, preIndex) {
                          return _c(
                            "div",
                            {
                              key: "pre" + preIndex,
                              staticClass: "swiper-slide",
                              staticStyle: { "min-width": "780px" },
                            },
                            [
                              _vm._m(1, true),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "center_top",
                                  staticStyle: {
                                    "grid-template-columns": "auto",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "grid",
                                        "grid-template-columns":
                                          "10% 20% 10% 15% 15% 20% 15%",
                                      },
                                    },
                                    [
                                      _c("el-image", {
                                        staticClass: "driver-img",
                                      }),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "text" }, [
                                        _c("span", { staticClass: "car" }, [
                                          _vm._v(
                                            _vm._s(_vm.dayListPreData.allNum) +
                                              " items"
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v("Total number of orders"),
                                        ]),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("order-list", {
                                attrs: {
                                  data: item.userList,
                                  type: "user",
                                  height: "calc(100vh - 334px)",
                                },
                                on: { onUser: _vm.handleArchives },
                              }),
                            ],
                            1
                          )
                        }
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "swiper-pagination" }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.type == 1,
                      expression: "type == 1",
                    },
                  ],
                  staticStyle: {
                    height: "calc(100vh - 170px)",
                    overflow: "scroll",
                  },
                },
                [
                  _vm._l(_vm.dayListData.car, function (item, carIndex) {
                    return _c(
                      "div",
                      { key: "car1" + carIndex, staticClass: "card" },
                      [
                        _c(
                          "div",
                          { staticClass: "title" },
                          [
                            carIndex == 0
                              ? [
                                  _c("div", { staticClass: "line" }),
                                  _vm._v(
                                    "\n              Driver to be assigned\n            "
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "center",
                            staticStyle: { "margin-bottom": "0" },
                          },
                          [
                            _c("div", { staticClass: "center_top" }, [
                              _c("img", {
                                staticClass: "driver-img",
                                attrs: {
                                  src: require("@/assets/imgs/car.svg"),
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "text" }, [
                                _c("span", [_vm._v("Total number of orders")]),
                                _vm._v(" "),
                                _c("span", { staticClass: "order" }, [
                                  _vm._v(_vm._s(item.allNum) + " items"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "text" }, [
                                _c("span", [_vm._v("Total number of barrels")]),
                                _vm._v(" "),
                                _c("span", { staticClass: "order" }, [
                                  _vm._v(_vm._s(item.allBuckets) + " "),
                                  item.restNum
                                    ? _c("span", [
                                        _vm._v(
                                          "(" + _vm._s(item.restNum) + ")"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "text" }, [
                                _c("span", [_vm._v("Car model")]),
                                _vm._v(" "),
                                _c("span", { staticClass: "car" }, [
                                  _vm._v(
                                    _vm._s(
                                      item.carType == 1 ? "Truck" : "Mini Van"
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "text" }, [
                                _c("span", [_vm._v("License plate")]),
                                _vm._v(" "),
                                _c("span", { staticClass: "car" }, [
                                  _vm._v(_vm._s(item.carNum)),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "text" }, [
                                _c("span", [_vm._v("Delivery time")]),
                                _vm._v(" "),
                                _c("span", { staticClass: "car" }, [
                                  _vm._v(_vm._s(item.driverTime + "h" || "-")),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                    "justify-content": "center",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleLoadCar(
                                            item,
                                            item.allNum
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Loading vehicle")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "mini",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeDate(item)
                                        },
                                      },
                                    },
                                    [_vm._v("Reschedule delivery")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticStyle: { padding: "0 20px 20px" } },
                              [
                                _vm._l(
                                  _vm.getList(item.productList),
                                  function (sku) {
                                    return _c(
                                      "span",
                                      {
                                        key: sku.productId,
                                        staticStyle: {
                                          color: "#00000073",
                                          "margin-right": "20px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(sku.productName) +
                                            "：\n                "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "car",
                                            staticStyle: {
                                              "font-size": "12px",
                                              color: "#000",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(sku.number) +
                                                "G\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                _vm._v(" "),
                                item.productList && item.productList.length > 4
                                  ? _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          type: "primary",
                                          underline: false,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleLoadCarDetail(
                                              item.carId
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("more>")]
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "item_list" },
                              _vm._l(item.orderList, function (item, i) {
                                return _c(
                                  "div",
                                  {
                                    key: i,
                                    class: "item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDetails(item.orderId)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "item_top" }, [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "btn",
                                          class: [
                                            item.isChange
                                              ? "isChangeDriver"
                                              : "",
                                            _vm.getOrderStatus(item.status),
                                          ],
                                        },
                                        [
                                          item.isException
                                            ? [
                                                _vm._v(
                                                  "\n                      EX-order " +
                                                    _vm._s(item.sort) +
                                                    "\n                    "
                                                ),
                                              ]
                                            : [
                                                _vm._v(
                                                  "\n                      Order " +
                                                    _vm._s(item.sort) +
                                                    "\n                    "
                                                ),
                                              ],
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-arrow-right order-right",
                                        class: [
                                          item.isChange ? "isChangeDriver" : "",
                                          _vm.getOrderStatus(item.status),
                                        ],
                                        staticStyle: { "font-size": "18px" },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c("div", [
                                        _c("span", { staticClass: "label" }, [
                                          _vm._v("NAME:"),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#000" } },
                                          [_vm._v(_vm._s(item.realName))]
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.dayListData.driver, function (item, index) {
                    return _c(
                      "div",
                      { key: "driver1" + index, staticClass: "card" },
                      [
                        _c(
                          "div",
                          { staticClass: "title" },
                          [
                            index == 0
                              ? [
                                  _c("div", { staticClass: "line" }),
                                  _vm._v(
                                    "\n              Order assigned\n            "
                                  ),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._m(2, true),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "center" }, [
                          _c("div", { staticClass: "center_bottom" }, [
                            _c("div", { staticClass: "center_bottom_left" }, [
                              _c("div", { staticClass: "center_bottom_a" }, [
                                _c("img", {
                                  staticClass: "driver-img",
                                  attrs: {
                                    src: require("@/assets/imgs/driver.webp"),
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticStyle: { width: "100%" } }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex align-center justify-between",
                                    },
                                    [
                                      _c("div", { staticClass: "car" }, [
                                        _vm._v(_vm._s(item.driverName)),
                                      ]),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass: "el-icon-refresh-right",
                                        staticStyle: {
                                          "font-size": "20px",
                                          cursor: "pointer",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.getDetail(item)
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex",
                                      staticStyle: { "margin-top": "5px" },
                                    },
                                    [
                                      _c("div", [
                                        _c("span", [_vm._v("car model:")]),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "car" }, [
                                          _vm._v(
                                            _vm._s(
                                              item.carType == 1
                                                ? "Truck"
                                                : "Mini Van"
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-left": "20px",
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("License plate:"),
                                          ]),
                                          _vm._v(" "),
                                          _c("span", { staticClass: "car" }, [
                                            _vm._v(_vm._s(item.carNum)),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "btn_box" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "is-ghost",
                                      attrs: { type: "default", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeDelivery(item)
                                        },
                                      },
                                    },
                                    [_vm._v("Change delivery")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "is-ghost",
                                      attrs: { type: "default", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goto("deliveryMap", {
                                            driverId: item.driverId,
                                            date: _vm.initialDate,
                                            carId: item.carId,
                                          })
                                        },
                                      },
                                    },
                                    [_vm._v("Delivery map")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "is-ghost",
                                      attrs: { type: "default", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.reorderingShow(item)
                                        },
                                      },
                                    },
                                    [_vm._v("Reordering")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "orderNum_box" }, [
                                _c("div", { staticClass: "orderNum_item" }, [
                                  _c("span", { staticClass: "orderNum" }, [
                                    _vm._v(_vm._s(item.allNum)),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v("Total number of orders"),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "orderNum_item" }, [
                                  _c("span", { staticClass: "orderNum" }, [
                                    _vm._v(_vm._s(item.deliveredNum || 0)),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("Order sent")]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "orderNum_item" }, [
                                  _c("span", { staticClass: "orderNum" }, [
                                    _vm._v(_vm._s(item.residueNum)),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("Remaining orders")]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "orderNum_box water" }, [
                                _c("div", { staticClass: "orderNum_item" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "orderNum",
                                      staticStyle: {},
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(item.driverTime + "h" || "-")
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("Delivery time")]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "orderNum_item" }, [
                                  _c("span", { staticClass: "orderNum" }, [
                                    _vm._v(_vm._s(item.takeNum)),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("Remaining buckets")]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "orderNum_item" }, [
                                  _c("span", { staticClass: "orderNum" }, [
                                    _vm._v(_vm._s(item.orderResidueNum)),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v("Remaining order buckets"),
                                  ]),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "item_list" },
                              _vm._l(item.orderList, function (child, i) {
                                return _c(
                                  "div",
                                  {
                                    key: i,
                                    class: "item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDetails(child.orderId)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          width: "350",
                                          trigger: "hover",
                                          effect: "light",
                                          placement: "top-start",
                                        },
                                      },
                                      [
                                        _c("template", { slot: "reference" }, [
                                          _c(
                                            "div",
                                            { staticClass: "item_top" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "btn",
                                                  class: [
                                                    child.isChange
                                                      ? "isChangeDriver"
                                                      : "",
                                                    _vm.getOrderStatus(
                                                      child.status
                                                    ),
                                                  ],
                                                },
                                                [
                                                  child.isException
                                                    ? [
                                                        _vm._v(
                                                          "\n                            EX-order " +
                                                            _vm._s(child.sort) +
                                                            "\n                          "
                                                        ),
                                                      ]
                                                    : [
                                                        _vm._v(
                                                          "\n                            Order " +
                                                            _vm._s(child.sort) +
                                                            "\n                          "
                                                        ),
                                                      ],
                                                ],
                                                2
                                              ),
                                              _vm._v(" "),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-arrow-right order-right",
                                                class: [
                                                  child.isChange
                                                    ? "isChangeDriver"
                                                    : "",
                                                  _vm.getOrderStatus(
                                                    child.status
                                                  ),
                                                ],
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("div", [
                                            _c("div", [
                                              _c(
                                                "span",
                                                { staticClass: "label" },
                                                [_vm._v("NAME:")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#000",
                                                  },
                                                },
                                                [_vm._v(_vm._s(child.realName))]
                                              ),
                                            ]),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [
                                          _c("span", { staticClass: "label" }, [
                                            _vm._v("ORDER ID:"),
                                          ]),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(child.orderId)),
                                          ]),
                                        ]),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.dayListData.provisional, function (item, index) {
                    return _c(
                      "div",
                      { key: "overTime" + index, staticClass: "card" },
                      [
                        _c(
                          "div",
                          { staticClass: "title" },
                          [
                            index == 0
                              ? [
                                  _c("div", { staticClass: "line" }),
                                  _vm._v(
                                    "\n              Temporary order\n            "
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "center" }, [
                          _c(
                            "div",
                            { staticClass: "center_top" },
                            [
                              _c("el-image", { staticClass: "driver-img" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "text" }, [
                                _c("span", [_vm._v("Total number of orders")]),
                                _vm._v(" "),
                                _c("span", { staticClass: "order" }, [
                                  _vm._v(_vm._s(item.allNum) + " items"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "btn_box" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "is-ghost",
                                      attrs: { type: "default", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeCar(item)
                                        },
                                      },
                                    },
                                    [_vm._v("Allocate vehicles")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "item_list" },
                            _vm._l(item.orderList, function (child, i) {
                              return _c(
                                "div",
                                {
                                  key: i,
                                  class: "item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onDetails(child.orderId)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        width: "350",
                                        trigger: "hover",
                                        effect: "light",
                                        placement: "top-start",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "reference" }, [
                                        _c("div", { staticClass: "item_top" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "btn",
                                              class: [
                                                child.isChange
                                                  ? "isChangeDriver"
                                                  : "",
                                                _vm.getOrderStatus(
                                                  child.status
                                                ),
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                "Order " + _vm._s(child.sort)
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass:
                                              "el-icon-arrow-right order-right",
                                            class: [
                                              child.isChange
                                                ? "isChangeDriver"
                                                : "",
                                              _vm.getOrderStatus(child.status),
                                            ],
                                          }),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [
                                          _c("div", [
                                            _c(
                                              "span",
                                              { staticClass: "label" },
                                              [_vm._v("NAME:")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: { color: "#000" },
                                              },
                                              [_vm._v(_vm._s(child.realName))]
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c("span", { staticClass: "label" }, [
                                          _vm._v("ORDER ID:"),
                                        ]),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(child.orderId)),
                                        ]),
                                      ]),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.dayListData.overTime, function (item, index) {
                    return _c(
                      "div",
                      { key: "overTime" + index, staticClass: "card" },
                      [
                        _c(
                          "div",
                          { staticClass: "title" },
                          [
                            index == 0
                              ? [
                                  _c("div", { staticClass: "line" }),
                                  _vm._v(
                                    "\n              Exception order\n            "
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "center" }, [
                          _c(
                            "div",
                            { staticClass: "center_top" },
                            [
                              _c("el-image", { staticClass: "driver-img" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "text" }, [
                                _c("span", [_vm._v("Total number of orders")]),
                                _vm._v(" "),
                                _c("span", { staticClass: "order" }, [
                                  _vm._v(_vm._s(item.allNum) + " items"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "btn_box" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "is-ghost",
                                      attrs: { type: "default", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeCar(item)
                                        },
                                      },
                                    },
                                    [_vm._v("Allocate vehicles")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "item_list" },
                            _vm._l(item.orderList, function (child, i) {
                              return _c(
                                "div",
                                {
                                  key: i,
                                  class: "item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onDetails(child.orderId)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        width: "350",
                                        trigger: "hover",
                                        effect: "light",
                                        placement: "top-start",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "reference" }, [
                                        _c("div", { staticClass: "item_top" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "btn",
                                              class: [
                                                child.isChange
                                                  ? "isChangeDriver"
                                                  : "",
                                                _vm.getOrderStatus(
                                                  child.status
                                                ),
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                "Order " + _vm._s(child.sort)
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass:
                                              "el-icon-arrow-right order-right",
                                            class: [
                                              child.isChange
                                                ? "isChangeDriver"
                                                : "",
                                              _vm.getOrderStatus(child.status),
                                            ],
                                          }),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [
                                          _c("div", [
                                            _c(
                                              "span",
                                              { staticClass: "label" },
                                              [_vm._v("NAME:")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: { color: "#000" },
                                              },
                                              [_vm._v(_vm._s(child.realName))]
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c("span", { staticClass: "label" }, [
                                          _vm._v("ORDER ID:"),
                                        ]),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(child.orderId)),
                                        ]),
                                      ]),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.dayListPreData.list, function (item, preIndex) {
                    return _c(
                      "div",
                      { key: "pre1" + preIndex, staticClass: "card" },
                      [
                        _vm._m(3, true),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "center",
                            staticStyle: { "margin-bottom": "0" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "center_top" },
                              [
                                _c("el-image", { staticClass: "driver-img" }),
                                _vm._v(" "),
                                _c("div", { staticClass: "text" }, [
                                  _c("span", [
                                    _vm._v("Total number of orders"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "order" }, [
                                    _vm._v(
                                      _vm._s(_vm.dayListPreData.allNum) +
                                        " items"
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "item_list" },
                              _vm._l(item.userList, function (item, userIndex) {
                                return _c(
                                  "div",
                                  {
                                    key: userIndex,
                                    staticClass: "item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleArchives(item.uid)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "item_top" }, [
                                      _c("span", { staticClass: "btn" }, [
                                        _vm._v(
                                          "Order " + _vm._s(userIndex + 1)
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-arrow-right order-right",
                                        staticStyle: { "font-size": "18px" },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c("div", [
                                        _c("span", { staticClass: "label" }, [
                                          _vm._v("NAME:"),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#000" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                item.firstName +
                                                  " " +
                                                  item.lastName
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tabPosition == "dayGridWeek",
              expression: "tabPosition == 'dayGridWeek'",
            },
          ],
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "week-grid",
            },
            _vm._l(_vm.weekList, function (item, index) {
              return _c("div", { key: index, staticClass: "week-item" }, [
                _c(
                  "div",
                  {
                    class:
                      "week-day " +
                      (item.name === _vm.currentDate
                        ? "active-day"
                        : "inactive-day"),
                    staticStyle: { "text-align": "center" },
                    on: {
                      click: function ($event) {
                        return _vm.handleChangeViewDate(item.name)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(item.weekDay) +
                        "\n          " +
                        _vm._s(_vm._f("formatTime")(item.name, "MM-DD")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "day-list",
                    class:
                      item.name === _vm.currentDate
                        ? "active-day"
                        : "inactive-day",
                  },
                  _vm._l(item.list, function (child, childIndex) {
                    return _c(
                      "div",
                      {
                        key: childIndex,
                        staticClass: "car-item",
                        class: child.driverId ? "IFP" : "",
                        style:
                          child.type == "pre-order"
                            ? "background: rgb(233,233,233);"
                            : child.driverId
                            ? "background: #BED4FF66"
                            : "",
                      },
                      [
                        child.type == "order"
                          ? _c("div", { staticClass: "car-item-top" }, [
                              _c("img", {
                                staticStyle: { width: "26px" },
                                attrs: {
                                  src: require("@/assets/imgs/user.svg"),
                                },
                              }),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      child.carType == 1 ? "Truck" : "Mini Van"
                                    ) +
                                    "：\n                "
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "car_link",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleCarClick(
                                          item.name,
                                          child.carId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(child.carNum))]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "flex-row-bet" }, [
                                  _c("div", [
                                    _vm._v(
                                      "Driver:" +
                                        _vm._s(
                                          _vm._f("filterEmpty")(
                                            child.driverName
                                          )
                                        )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v("Items：" + _vm._s(child.allNum)),
                                  ]),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        child.type == "pre-order"
                          ? _c(
                              "div",
                              {
                                staticClass: "car-item-top",
                                staticStyle: {
                                  "grid-template-columns": "100%",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              Pre-order quantity：" +
                                    _vm._s(child.allNum) +
                                    "\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            ref: child.orderList
                              ? child.orderList[0].orderId
                              : "noRef",
                            refInFor: true,
                            staticClass: "car-item-center",
                            class: child.eclipse
                              ? "eclipse "
                              : "no-eclipse " + child.orderList[0].orderId,
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "car-item-center-content",
                                class: child.orderList[0].orderId,
                              },
                              _vm._l(
                                child.orderList,
                                function (order, orderIndex) {
                                  return _c(
                                    "div",
                                    {
                                      key: orderIndex,
                                      staticClass: "order-user-item",
                                      class: [
                                        order.isChange ? "isChangeDriver" : "",
                                        _vm.getOrderStatus(order.status),
                                      ],
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDetail(order)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(order.realName) +
                                          "\n                "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        child.isNeedEclipse
                          ? _c(
                              "div",
                              {
                                staticClass: "car-item-bottom",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleTapClick(
                                      index,
                                      childIndex,
                                      child.eclipse
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(child.eclipse ? "Close" : "Expand") +
                                    "\n              "
                                ),
                                _c("i", {
                                  class: child.eclipse
                                    ? "el-icon-arrow-up"
                                    : "el-icon-arrow-down",
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
              ])
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _c("Reordering", { ref: "ReorderingForm", on: { update: _vm.loadData } }),
      _vm._v(" "),
      _c("loadingVehicle", {
        ref: "loadCar",
        attrs: { deliveryDate: _vm.initialDate },
        on: { update: _vm.loadData },
      }),
      _vm._v(" "),
      _c("loadingVehicleDetail", { ref: "loadCarDetail" }),
      _vm._v(" "),
      _c("change-delivery-modal", {
        ref: "changeDeliveryForm",
        attrs: { deliveryDate: _vm.initialDate },
        on: { update: _vm.loadData },
      }),
      _vm._v(" "),
      _c("allocate-vehicles", {
        ref: "allocateVehicles",
        attrs: { deliveryDate: _vm.initialDate },
        on: { update: _vm.loadData },
      }),
      _vm._v(" "),
      _c("order-details", {
        ref: "orderDetail",
        attrs: { orderId: _vm.orderId },
        on: { update: _vm.loadData },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "order-delivery" }, [
      _c("span", { staticClass: "labels row-completed" }, [
        _vm._v("Delivered"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "labels row-ischange" }, [
        _vm._v("Change driver"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "labels" }, [_vm._v("Uncompleted delivery")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "line" }),
      _vm._v("\n              Pre order\n            "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "order-delivery" }, [
      _c("span", { staticClass: "labels completed" }, [_vm._v("Delivered")]),
      _vm._v(" "),
      _c("span", { staticClass: "labels ischange" }, [_vm._v("Change driver")]),
      _vm._v(" "),
      _c("span", { staticClass: "labels" }, [_vm._v("Uncompleted delivery")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "line" }),
      _vm._v("\n            Pre order\n          "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }