//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as roleApi from '@/api/role.js';
import edit from './edit';
import { checkPermi } from "@/utils/permission"; // 权限判断函数
export default {
  // name: "index"
  components: {
    edit: edit
  },
  data: function data() {
    return {
      constants: this.$constants,
      listData: {
        list: []
      },
      listPram: {
        createTime: null,
        updateTime: null,
        level: null,
        page: 1,
        limit: this.$constants.page.limit[0],
        roleName: null,
        rules: null,
        status: null
      },
      menuList: [],
      editDialogConfig: {
        visible: false,
        isCreate: 0,
        // 0=创建，1=编辑
        editData: {}
      }
    };
  },
  mounted: function mounted() {
    this.handleGetRoleList();
  },
  methods: {
    checkPermi: checkPermi,
    handlerOpenDel: function handlerOpenDel(rowData) {
      var _this = this;
      this.$confirm('Data prior to deletion').then(function () {
        roleApi.delRole(rowData).then(function (data) {
          _this.$message.success('successful');
          _this.handleGetRoleList();
        });
      });
    },
    handleGetRoleList: function handleGetRoleList() {
      var _this2 = this;
      roleApi.getRoleList(this.listPram).then(function (data) {
        _this2.listData = data;
      });
    },
    handlerOpenEdit: function handlerOpenEdit(isCreate, editDate) {
      isCreate === 1 ? this.editDialogConfig.editData = editDate : this.editDialogConfig.editData = {};
      this.editDialogConfig.isCreate = isCreate;
      this.editDialogConfig.visible = true;
    },
    hideEditDialog: function hideEditDialog() {
      this.editDialogConfig.visible = false;
      this.handleGetRoleList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPram.limit = val;
      this.handleGetRoleList(this.listPram);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listPram.page = val;
      this.handleGetRoleList(this.listPram);
    },
    //修改状态
    handleStatusChange: function handleStatusChange(row) {
      var _this3 = this;
      roleApi.updateRoleStatus(row).then(function (res) {
        _this3.$message.success('successful');
        _this3.handleGetRoleList();
      });
    },
    resetQuery: function resetQuery() {
      this.listPram.roleName = '';
      this.handleGetRoleList();
    }
  }
};