var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      staticStyle: { width: "100%", "min-width": "600px" },
      attrs: {
        data: _vm.data,
        height: _vm.height,
        border: "",
        "header-cell-style": {
          fontWeight: "bold",
          background: "#f8f8f9",
          color: "#515a6e",
          height: "40px",
        },
        "row-class-name": _vm.tableRowClassName,
      },
    },
    [
      _c("el-table-column", {
        attrs: { prop: "sort", label: "Delivery order", "min-width": "150" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                scope.row.isException
                  ? _c("span", [
                      _vm._v(
                        "\n        EX-order " +
                          _vm._s(scope.row.sort) +
                          "\n      "
                      ),
                    ])
                  : _c("span", [
                      _vm._v(" Order " + _vm._s(scope.row.sort) + " "),
                    ]),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "username", prop: "realName", "min-width": "300" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _vm.type == "order"
                  ? _c("span", [_vm._v(_vm._s(row.realName))])
                  : _c("span", [
                      _vm._v(_vm._s(row.firstName + " " + row.lastName)),
                    ]),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Order status", prop: "orderId", "min-width": "150" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  _vm._s(_vm.getOrderStatus(scope.row.status) || "-") + "\n    "
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "operation", align: "center", "min-width": "150px" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm.type == "order"
                  ? _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.onDetails(scope.row.orderId)
                          },
                        },
                      },
                      [_vm._v("Order details")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.type == "user"
                  ? _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.onUser(scope.row.uid)
                          },
                        },
                      },
                      [_vm._v("User details")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }